import React from 'react';
import styled from 'styled-components';
import silverLeft from '../../assets/Marcos/plata-2.png';
import silverRight from '../../assets/Marcos/plata-3.png';
import badge from '../../assets/Marcos/plata-1.png';
import { Border } from './Border';

const SilverBorder = ({ children }) => (
  <Border color="#A9A9A9">
    <LeftSilver />
    <CenterBadge />
    <RightSilver />
    {children}
  </Border>
);

export const LeftSilver = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;
  left: -9px;
  top: -10px;
  background: url(${silverLeft});
  background-size: cover;
`;

export const RightSilver = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;
  right: -9px;
  top: -10px;
  background: url(${silverRight});
  background-size: cover;
`;

export const CenterBadge = styled.div`
  height: 30px;
  width: 80px;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: -15px;
  background: url(${badge});
  background-size: cover;
`;

export default SilverBorder;
