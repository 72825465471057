import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { Spinner } from '@ui-kitten/components';
import * as Analytics from 'expo-firebase-analytics';
import { useTranslation } from 'react-i18next';
import FirebaseService from '../services/firebase.ts';

import {
  AuthForm,
  AuthContainer,
  PokyGG,
  AuthText,
  AuthFields,
  RedText,
  Auth,
} from '../styles/Shared';
import { EyeIcon, EyeOffIcon, EmailIcon } from '../components/Icons.tsx';
import Button from '../components/Button/Button';
import Input from '../components/Input';
import useInput from '../hooks/useInput';
import Checkbox from '../components/CheckBox/Checkbox';

const Signup = () => {
  const email = useInput('', 'email');
  const password = useInput();
  const { t } = useTranslation(['signup', 'errors', 'login']);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const onSignup = async e => {
    e.preventDefault();
    if (!email.value) {
      email.onSetError(t('errors:inputEmpty'));
      return;
    }

    if (!password.value) {
      password.onSetError(t('errors:inputEmpty'));
      return;
    }
    if (!acceptedTerms) {
      return;
    }

    try {
      setLoading(true);
      await FirebaseService.createUserWithEmailAndPassword(
        email.value,
        password.value
      );
      await Analytics.logEvent('Sig_Up');
      setLoading(false);
      navigate('/create-summoner');
    } catch (error) {
      setLoading(false);
      if (error.code.includes('auth/email-already-in-use')) {
        email.onSetError(t('emailRegisterd'));
      }

      if (error.message.includes('Password should be at least 6 characters')) {
        password.onSetError(t('passwordErr'));
      }
    }
  };

  return (
    <Auth>
      <AuthContainer>
        <AuthForm onSubmit={onSignup}>
          <PokyGG />
          <AuthFields>
            <Input
              placeholder={t('login:email')}
              Icon={EmailIcon}
              value={email.value}
              onChange={email.onChange}
              onTouchInput={email.onTouch}
            />
            <RedText>{email.error}</RedText>
            <Input
              placeholder={t('password')}
              Icon={showPassword ? EyeIcon : EyeOffIcon}
              value={password.input}
              secureInput={!showPassword}
              onChange={password.onChange}
              onTouchInput={password.onTouch}
              onClickIcon={() => setShowPassword(!showPassword)}
            />
            <RedText>{password.error}</RedText>
            <Checkbox
              text=""
              checked={acceptedTerms}
              onToggle={checked => setAcceptedTerms(checked)}
            >
              {t('checkboxTerms')}
              <strong>
                <a
                  target="_blank"
                  style={{ cursor: 'pointer', color: '#fff' }}
                  href="https://poky.gg/terms-of-use-and-conditions/"
                >
                  {' '}
                  {t('terms')}
                </a>
              </strong>
            </Checkbox>
            <Button
              background="#3C25FA"
              color="#fff"
              type="submit"
              width="100%"
            >
              {loading ? <Spinner status="basic" /> : t('signupBtn')}
            </Button>
          </AuthFields>

          <Button
            onClick={() => navigate('/login')}
            background="transparent"
            color="#fff"
            width="100%"
          >
            {t('loginLink')}
          </Button>
        </AuthForm>
      </AuthContainer>
    </Auth>
  );
};

export default Signup;
