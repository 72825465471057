import { ScrollView } from 'react-native';
import React, { useEffect, useState } from 'react';
import {
  Layout,
  Text,
  ListItem,
  Button,
  Icon,
  List,
  Spinner,
} from '@ui-kitten/components';
import * as Analytics from 'expo-firebase-analytics';
import isPlainObject from 'lodash/isPlainObject';
import SummonerInfoHeader from '../../components/SummonerInfoHeader';
import i18n from '../../i18n';
import FirebaseService from '../../services/firebase';
import HistoryCard from './extra/HistoryCard';

const data = new Array(8).fill({
  title: 'Title for Item',
  description: 'Description for Item',
});

export const ListCompositeItemShowcase = () => {
  const renderItemAccessory = style => (
    <Button size="tiny" style={style}>
      FOLLOW
    </Button>
  );

  const renderItemIcon = style => <Icon {...style} name="person" />;

  const renderItem = ({ item, index }) => (
    <ListItem
      title={`${item.title} ${index + 1}`}
      description={`${item.description} ${index + 1}`}
      icon={renderItemIcon}
      accessory={renderItemAccessory}
    />
  );

  return <List data={data} renderItem={renderItem} />;
};

const History = ({ navigation }) => {
  const [historyChallenges, setHistoryChallenges] = useState();

  useEffect(() => {
    (async function () {
      const {
        data: { data: historyChallenges },
      } = await FirebaseService.getHistoryChallenges();
      setHistoryChallenges(historyChallenges);
    })();
  }, []);

  return (
    <>
      <SummonerInfoHeader />
      <Layout level="2" style={{ flex: 1, paddingHorizontal: 34 }}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Text category="s1" style={{ marginBottom: 12, marginTop: 16 }}>
            {i18n.t('history.pastChallenge')}
          </Text>
          {historyChallenges && historyChallenges.length ? (
            historyChallenges.map(challenge =>
              isPlainObject(challenge) ? (
                <HistoryCard
                  {...challenge}
                  key={challenge.id}
                  onPress={() =>
                    navigation.navigate({
                      name: 'ChallengeDetails',
                      params: { challenge },
                    })
                  }
                />
              ) : null
            )
          ) : (
            <Spinner />
          )}
        </ScrollView>
      </Layout>
    </>
  );
};

export default History;
