import React, { useContext } from "react";
import { View, Alert, Image } from "react-native";
import {
  Button,
  Input,
  StyleService,
  useStyleSheet,
  Text,
} from "@ui-kitten/components";
import { ImageOverlay } from "./extra/image-overlay.component";
import { PersonIcon } from "../../components/Icons";
import { KeyboardAvoidingView } from "./extra/3rd-party";
import FirebaseService, { AuthContext } from "../../services/firebase";
import ServerSelector from "../../components/ServerSelector";
import alert from "../../services/alert";
import * as Analytics from "expo-firebase-analytics";
import i18n from "../../i18n";

const SERVERS = [
  //{ text: "BR1", disabled: true},
  //{ text: "EUN1", disabled: true},
  //{ text: "EUW1", disabled: true },
  //{ text: "KR", disabled: true },
  { text: "LAN", value: "LA1" },
  { text: "LAS", value: "LA2" },
  { text: "NA", value: "NA1" },
  //{ text: "OC1", disabled: true },
  //{ text: "TR1", disabled: true },
  //{ text: "RU", disabled: true },
  //{ text: "JP1", disabled: true },
  //{ text: "PBE", disabled: true },
];

export default (): React.ReactElement => {
  const [userName, setUserName] = React.useState<string>();
  const [server, setServer] = React.useState<any>();
  const [dateOfBirth, setDateOfBirth] = React.useState<any>();
  const { setUser, setSummoner, setIsSettedSummer } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);

  const styles = useStyleSheet(themedStyles);

  const onConfirm = async (): Promise<void> => {
    if (!userName || !server || !server.value) {
      return;
    }
    setLoading(true);
    try {
      const {data} = await FirebaseService.createSummoner(userName, server.value);
      if(!data.data){
        alert(i18n.t("summoner.userAlreadyTaken"));
        setLoading(false);
        return;
      }
      await FirebaseService.refreshUser();
      const summoner = await FirebaseService.getSummonerByUID(
        FirebaseService.getCurrentUser().uid
      );
      await setUser(FirebaseService.getCurrentUser().toJSON());
      await setSummoner(summoner?.data);
      await Analytics.logEvent("Summoner_Selection", {
        purpose: "Successful Summoner Selection",
        summoner: userName,
      });
      setIsSettedSummer(true);
      console.log("Evento Summoner selection --- Summoner", userName);
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  const onLogoutButton = (): void => {
    FirebaseService.signOut();
  };

  const onSetServer = (server): void => {
    setServer(server);
  };

  return (
      <ImageOverlay
        style={styles.container}
        source={require("./assets/image-background.jpg")}
      >
        <View style={styles.headerContainer}>
          <Image
            style={{
              resizeMode: "contain",
              height: 100,
              maxWidth: "80%",
              minWidth: 318,
            }}
            source={require("../../assets/logo.png")}
          />
          <Text style={styles.signInLabel} category="s1" status="control">
            {i18n.t("summoner.description")}
          </Text>
        </View>
        <View style={styles.formContainer}>
          <Input
            status="control"
            autoCapitalize="none"
            placeholder={i18n.t("summoner.name")}
            icon={PersonIcon}
            value={userName}
            onChangeText={setUserName}
          />
          <ServerSelector
            style={styles.formInput}
            status="control"
            data={SERVERS}
            selectedOption={server}
            onSelect={onSetServer}
            placeholder={i18n.t("summoner.server")}
          />
        </View>
        <Button
          style={styles.signUpButton}
          size="large"
          status="control"
          onPress={onConfirm}
        >
          {loading ? i18n.t("summoner.loading") : i18n.t("summoner.confirm")}
        </Button>
        <Button
          style={styles.signUpButton}
          appearance="ghost"
          status="control"
          onPress={onLogoutButton}
        >
          {i18n.t("summoner.logout")}
        </Button>
      </ImageOverlay>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: "background-basic-color-1",
  },
  signInLabel: {
    marginTop: 40,
    marginHorizontal: 10,
  },
  editAvatarButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  headerContainer: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: 216,
  },
  formContainer: {
    flex: 1,
    paddingTop: 32,
    paddingHorizontal: 16,
  },
  formInput: {
    marginTop: 16,
  },
  termsCheckBox: {
    marginTop: 24,
  },
  termsCheckBoxText: {
    color: "text-control-color",
  },
  signUpButton: {
    marginHorizontal: 16,
  },
  signInButton: {
    marginVertical: 12,
    marginHorizontal: 16,
  },
});
