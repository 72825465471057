import styled from 'styled-components';
import pokygg from '../assets/logo.png';
import loginImg from '../assets/ashe.jpg';

export const WrapperPageContent = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  display: flex;
  flex-wrap: wrap;
`;

export const Auth = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${loginImg});
  background-size: cover;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(5, 25, 90, 0.69);
  }
`;

export const SectionName = styled.div`
  font-size: 1.4rem;
  text-transform: uppercase;
  margin: 1.5rem 0;
  width: 100%;
  color: #fff;
  font-weight: bold;
`;

export const AuthContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
`;

export const WrapperAds = styled.div`
  width: 100%;
`;

export const AuthForm = styled.form`
  width: 100%;
  padding-top: 9rem;
`;

export const PokyGG = styled.div`
  width: 250px;
  margin: 0 auto 2rem;
  height: 80px;
  background: url(${pokygg});
  background-size: cover;
`;

export const AuthText = styled.div`
  text-align: center;
  padding: 1rem 0;
  font-size: 1.4rem;
  color: #fff;
  font-weight: bold;
`;

export const AuthFields = styled.div`
  margin-top: 3rem;
  width: 100%;
  max-width: 400px;
  margin: auto;
`;

export const RedText = styled.p`
  color: red;
  font-size: 1.2rem;
  padding: 1rem 0;
`;
