import React, { useState } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import { Spinner } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import FirebaseService from '../services/firebase.ts';
import {
  Auth,
  AuthForm,
  AuthContainer,
  PokyGG,
  AuthText,
  AuthFields,
  RedText,
} from '../styles/Shared';
import { EyeIcon, EyeOffIcon, PersonIcon } from '../components/Icons.tsx';
import Button from '../components/Button/Button';
import Input from '../components/Input';
import useInput from '../hooks/useInput';

const Login = () => {
  const email = useInput('', 'email');
  const { t } = useTranslation(['login', 'errors']);

  const password = useInput();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const onLogin = async e => {
    e.preventDefault();
    if (!email.value || !password.value) {
      email.onSetError(t('errors:inputEmpty'));
      password.onSetError(t('errors:inputEmpty'));
      return;
    }
    try {
      setLoading(true);
      await FirebaseService.signInWithEmailAndPassword(
        email.value,
        password.value
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.code.includes('auth/user-not-found')) {
        email.onSetError(t('errors:emailNotRegister'));
      }

      if (error.code.includes('auth/wrong-password')) {
        password.onSetError(t('errors:wrongPassword'));
      }
    }
  };

  return (
    <Auth>
      <AuthContainer>
        <AuthForm onSubmit={onLogin}>
          <PokyGG />
          <AuthText>{t('login')}</AuthText>
          <AuthFields>
            <Input
              placeholder={t('email')}
              Icon={PersonIcon}
              value={email.value}
              onChange={email.onChange}
              onTouchInput={email.onTouch}
            />
            <RedText>{email.error}</RedText>
            <Input
              placeholder={t('password')}
              Icon={showPassword ? EyeIcon : EyeOffIcon}
              value={password.input}
              secureInput={!showPassword}
              onChange={password.onChange}
              onTouchInput={password.onTouch}
              onClickIcon={() => setShowPassword(!showPassword)}
            />
            <RedText>{password.error}</RedText>

            <Button
              onClick={() => navigate('/reset-password')}
              background="transparent"
              color="#fff"
              width="100%"
              align="flex-end"
              style={{ padding: '0 0 20px' }}
            >
              Forgot your password?
            </Button>
            <Button
              background="#3C25FA"
              color="#fff"
              type="submit"
              width="100%"
            >
              {loading ? <Spinner status="basic" /> : t('loginBtn')}
            </Button>
          </AuthFields>

          <Button
            onClick={() => navigate('/signup')}
            background="transparent"
            color="#fff"
            width="100%"
          >
            {t('signupLink')}
          </Button>
        </AuthForm>
        <Text>{t('loginInfo')}</Text>
        <Text>{t('loginInfo2')}</Text>
      </AuthContainer>
    </Auth>
  );
};

const Text = styled.p`
  font-size: 1.1rem;
  color: #fff;
  text-align: center;
  margin-bottom: 1rem;
`;

export default Login;
