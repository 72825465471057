import React, { useState } from 'react';
import { Spinner } from '@ui-kitten/components';
import { navigate } from '@reach/router';
import * as Analytics from 'expo-firebase-analytics';
import { useTranslation } from 'react-i18next';
import FirebaseService from '../services/firebase.ts';

import {
  Auth,
  AuthContainer,
  AuthForm,
  PokyGG,
  AuthText,
  AuthFields,
  RedText,
} from '../styles/Shared';
import { PersonIcon } from '../components/Icons.tsx';
import useInput from '../hooks/useInput';
import ServerSelector from '../components/ServerSelector.tsx';
import Button from '../components/Button/Button';
import Input from '../components/Input';

const SERVERS = [
  // { text: "BR1", disabled: true},
  // { text: "EUN1", disabled: true},
  // { text: "EUW1", disabled: true },
  // { text: "KR", disabled: true },
  { text: 'LAN', value: 'LA1' },
  { text: 'LAS', value: 'LA2' },
  { text: 'NA', value: 'NA1' },
  // { text: "OC1", disabled: true },
  // { text: "TR1", disabled: true },
  // { text: "RU", disabled: true },
  // { text: "JP1", disabled: true },
  // { text: "PBE", disabled: true },
];

const CreateSummoner = () => {
  const userName = useInput();
  const [server, setServer] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['summoner', 'errors']);
  const onSetServer = sv => {
    setServer(sv);
  };

  const onLogout = () => {
    FirebaseService.signOut();
    navigate('/');
  };

  const onSubmit = async e => {
    e.preventDefault();
    if (!userName || !server || !server.value) {
      userName.onSetError(t('errors:inputEmpty'));
      return;
    }
    setLoading(true);
    try {
      const { data } = await FirebaseService.createSummoner(
        userName.value,
        server.value
      );

      if (!data.data) {
        userName.onSetError(t('errors:notSummoner'));
        setLoading(false);
        return;
      }

      await FirebaseService.refreshUser();
      await Analytics.logEvent('Summoner_Selection', {
        screen: 'Summoner_selection',
        purpose: 'Successful Summoner Selection',
        summoner: userName,
      });
      console.log('Evento Summoner selection web --- Summoner', userName);
      navigate('/');
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  return (
    <Auth>
      <AuthContainer>
        <AuthForm onSubmit={onSubmit}>
          <PokyGG />
          <AuthText>{t('description')}</AuthText>
          <AuthFields>
            <Input
              placeholder={t('name')}
              Icon={PersonIcon}
              value={userName.value}
              onChange={userName.onChange}
              onTouchInput={userName.onTouch}
            />
            <RedText>{userName.error}</RedText>

            <ServerSelector
              status="control"
              data={SERVERS}
              selectedOption={server}
              onSelect={onSetServer}
              placeholder={t('server')}
            />

            <Button
              background="#3C25FA"
              color="#fff"
              type="submit"
              width="100%"
              style={{ marginTop: 15 }}
            >
              {loading ? <Spinner status="basic" /> : t('confirm')}
            </Button>
            <Button
              onClick={onLogout}
              background="transparent"
              color="#fff"
              width="100%"
            >
              {t('logout')}
            </Button>
          </AuthFields>
        </AuthForm>
      </AuthContainer>
    </Auth>
  );
};

export default CreateSummoner;
