import React from "react";
import Gold from "./Gold";
import Silver from "./Silver";
import Bronce from "./Bronce";
import styled from "styled-components";
import { View } from "react-native";

const Border = ({ position, children, activeUser }) => {
  if (position === 1) {
    return <Gold activeUser={activeUser}>{children}</Gold>;
  }
  if (position === 2) {
    return <Silver activeUser={activeUser}>{children}</Silver>;
  }
  if (position === 3) {
    return <Bronce activeUser={activeUser}>{children}</Bronce>;
  }

  return children;
};

export const BorderWrapper = styled(View)`
  width: 100%;
  height: 50px;
  flex-direction: row;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  position: relative;
  border: 1px solid ${(props) => props.color};
  background-color: ${(props) =>
    props.activeUser ? "#5731fe" : "transparent"};
`;

export default Border;
