import React from "react";
import { RadioGroup, Radio, Text } from "@ui-kitten/components";
import { View, StyleSheet } from "react-native";

const ServerSelector = ({
  style,
  data,
  selectedOption,
  onSelect,
  placeholder,
}) => {
  const selectedIndex = selectedOption
    ? data.findIndex((option) => option.value === selectedOption.value)
    : null;

  const onChange = (index) => {
    onSelect && onSelect(data[index]);
  };
  return (
    <View style={style}>
      <Text>{placeholder}</Text>
      <RadioGroup selectedIndex={selectedIndex} onChange={onChange}>
        {data.map((option) => (
          <Radio style={styles.radio} text={option.text} key={option.value} />
        ))}
      </RadioGroup>
    </View>
  );
};

export const styles = StyleSheet.create({
  text: {
    marginVertical: 8,
  },
  radio: {
    marginVertical: 8,
  },
});

export default ServerSelector;
