import React from "react";
import { LayoutContainer, LayoutContent, Footer } from "./Layout.style";
import Nav from "../Nav/Nav";
import TopBar from "../TopBar/TopBar";
import GlobalStyles from "../../styles/GlobalStyles";

const Layout = ({ children }) => (
  <>
    <LayoutContainer>
      <Nav />
      <LayoutContent>
        <TopBar />
        {children}
      </LayoutContent>
      <Footer>&copy; Copyright ByYuto LLC</Footer>
    </LayoutContainer>
  </>
);

export default Layout;
