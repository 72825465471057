import React, { useEffect } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Button, Input, Text } from '@ui-kitten/components';
import { ImageOverlay } from './extra/image-overlay.component';
import {
  EyeIcon,
  EyeOffIcon,
  PersonIcon,
  BackIcon,
} from '../../components/Icons';
import { KeyboardAvoidingView } from './extra/3rd-party';
import FirebaseService from '../../services/firebase';
import alert from '../../services/alert';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { useSafeArea } from 'react-native-safe-area-context';
import * as Analytics from 'expo-firebase-analytics';
import i18n from '../../i18n';

const Login = ({ navigation }): React.ReactElement => {
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const insets = useSafeArea();

  useEffect(() => {
    Analytics.logEvent('Log_in', {
      screen: 'Log_in',
      purpose: 'enter to screen Log_in',
    });
  }, []);

  const onSignInButtonPress = async (): Promise<void> => {
    try {
      setLoading(true);
      await FirebaseService.signInWithEmailAndPassword(email.trim(), password);
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSignUpButtonPress = (): void => {
    navigation && navigation.navigate('SignUp');
  };

  const onForgotPasswordButtonPress = (): void => {
    navigation && navigation.navigate("ResetPassword");
  };

  const onPasswordIconPress = (): void => {
    setPasswordVisible(!passwordVisible);
  };

  const onRenderContent = () => (
    <ScrollView>
      <View
        style={{
          marginTop: 10 + insets.top,
          marginLeft: 10 + insets.left,
          alignSelf: 'flex-start',
          width: 45,
        }}
      >
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <BackIcon height={30} width={30} tintColor="white" />
        </TouchableOpacity>
      </View>
      <View style={styles.headerContainer}>
        <Image
          style={{
            resizeMode: 'contain',
            height: 100,
            maxWidth: '80%',
            minWidth: 318,
          }}
          source={require('../../assets/logo.png')}
        />
        <Text style={styles.signInLabel} category="s1" status="control">
          {i18n.t('login.login')}
        </Text>
      </View>

      <View style={styles.formContainer}>
        <Input
          status="control"
          placeholder={i18n.t('login.email')}
          autoCapitalize="none"
          icon={PersonIcon}
          value={email}
          onChangeText={setEmail}
        />
        <Input
          style={styles.passwordInput}
          status="control"
          placeholder={i18n.t('login.password')}
          icon={passwordVisible ? EyeIcon : EyeOffIcon}
          value={password}
          secureTextEntry={!passwordVisible}
          onChangeText={setPassword}
          onIconPress={onPasswordIconPress}
        />
        <View style={styles.forgotPasswordContainer}>
          <Button
            style={styles.forgotPasswordButton}
            appearance="ghost"
            status="control"
            onPress={onForgotPasswordButtonPress}
          >
            {i18n.t("login.forgotPassword")}
          </Button>
        </View>
      </View>
      <Button
        style={styles.signInButton}
        status="control"
        size="large"
        onPress={onSignInButtonPress}
      >
        {loading ? i18n.t('summoner.loading') : i18n.t('login.loginBtn')}
      </Button>
      <Button
        style={styles.signUpButton}
        appearance="ghost"
        status="control"
        onPress={onSignUpButtonPress}
      >
        {i18n.t('login.signupLink')}
      </Button>
      <Text category="c1" style={styles.agreementCopy}>
        {i18n.t('login.loginInfo')}
      </Text>
      <Text category="c1" style={styles.agreementCopy}>
        {i18n.t('login.loginInfo2')}
      </Text>
    </ScrollView>
  );

  return (
      <ImageOverlay
        style={styles.container}
        source={require('./assets/image-background.jpg')}
      >
        {onRenderContent()}
      </ImageOverlay>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 216,
  },
  formContainer: {
    flex: 1,
    marginTop: 0,
    paddingHorizontal: 16,
  },
  signInLabel: {
    marginTop: 16,
  },
  signInButton: {
    marginHorizontal: 16,
  },
  signUpButton: {
    marginVertical: 12,
    marginHorizontal: 16,
  },
  agreementCopy: {
    marginVertical: 12,
    marginHorizontal: 30,
  },
  forgotPasswordContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  passwordInput: {
    marginTop: 16,
  },
  forgotPasswordButton: {
    paddingHorizontal: 0,
  },
});

export default Login;
