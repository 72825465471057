import React from "react";
import { Card, Modal, Text, Button } from "@ui-kitten/components";
import { StyleSheet, Image, View } from "react-native";
import rp from "../assets/rp.png";
import poky from "../assets/poky.png";
import i18n from "../i18n/";

const ModalWinner = ({
  buttonText = "Claim",
  onPress,
  onClose,
  visible,
  data = {},
}) => {
  return (
    <Modal
      visible={visible}
      backdropStyle={styles.backdrop}
      onBackdropPress={onClose}
      style={{ position: "relative" }}
    >
      <Card
        disabled={true}
        style={{
          backgroundColor: "#5731FE",
          minWidth: "80%",
          minHeight: 260,
          zIndex: 1,
        }}
      >
        <View
          style={{
            alignItems: "center",
            position: "relative",
            justifyContent: "center",
          }}
        >
          <Image
            source={data?.data?.prize?.type === "RP" ? rp : poky}
            style={styles.modalImg}
          />
          <Text category="h5" style={{ color: "#fff" }}>
            {data?.data?.prize?.value} {data?.data?.prize?.type}
          </Text>
        </View>
        <Text style={styles.closeIcon} onPress={onClose}>
          X
        </Text>
        <Text style={{ fontSize: 12, textAlign: "center" }}>{data?.body}</Text>
        <Button style={styles.button} status="basic" onPress={onPress}>
          {data?.data?.prize?.type === "RP"
            ? i18n.t("poppuWinner.button")
            : buttonText}
        </Button>
      </Card>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 192,
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  closeIcon: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  modalImg: {
    width: 170,
    height: 80,
    resizeMode: "cover",
    margin: "auto",
  },
  mainText: {
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  button: {
    marginTop: 10,
  },
});

export default ModalWinner;
