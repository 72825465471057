import React, { useState, useEffect } from 'react';
import { GiTrophy } from 'react-icons/gi';
import { AiFillFileText } from 'react-icons/ai';
import { BsPersonFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import {
  NavItems,
  NavItem,
  NavContainer,
  LogoWeb,
  NavItemButton,
} from './Nav.style';
import Profile from '../Profile';

const Nav = () => {
  const [openProfile, setOpenProfile] = useState(false);
  const { t } = useTranslation(['nav']);

  useEffect(() => {
    if (openProfile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openProfile]);

  const toggleProfile = () => setOpenProfile(!openProfile);

  return (
    <NavContainer>
      <LogoWeb />
      <NavItems>
        <NavItem to="/">
          <GiTrophy size={30} style={{ marginBottom: 5 }} />
          {t('challenge')}
        </NavItem>
        <NavItem to="/history">
          <AiFillFileText size={30} style={{ marginBottom: 5 }} />
          {t('history')}
        </NavItem>
        <NavItemButton onClick={() => toggleProfile()} active={openProfile}>
          <BsPersonFill size={30} style={{ marginBottom: 5 }} />
          {t('profile')}
        </NavItemButton>
      </NavItems>
      <div />
      {openProfile && <Profile toggle={toggleProfile} />}
    </NavContainer>
  );
};

export default Nav;
