import React from 'react';
import styled from 'styled-components';
import FacePile from 'react-native-face-pile';
import formatDistance from 'date-fns/formatDistance';
import { useTranslation } from 'react-i18next';
import { es, enUS } from 'date-fns/locale';
import i18n from '../i18n/i18web';
const renderLocale = () =>
  i18n.languages[0].includes('es')
    ? { locale: es, addSuffix: true }
    : { locale: enUS, addSuffix: true };
const FeatureCard = ({
  title,
  photoURL,
  endDate,
  participants = [],
  onClick,
  title_es,
  excerpt,
  excerpt_es,
}) => {
  const { t } = useTranslation('date');
  return (
    <FeatureCardContainer onClick={onClick}>
      <FeatureCardImg src={photoURL} />
      <FeatureCenter>
        <span>
          {endDate
            ? `${t('endsin')} ${formatDistance(
                endDate,
                new Date(),
                renderLocale()
              )}`
            : null}
        </span>
      </FeatureCenter>
      <FeatureCardBody>
        <FeatureFlex>
          <ChallengeTitle>
            {i18n.languages[0].includes('es') ? title_es : title}
          </ChallengeTitle>
          <FeatureParticipants>
            <FacePile
              numFaces={4}
              faces={Object.values(participants)
                .slice(0, 5)
                .map(p => ({
                  id: p.name,
                  imageUrl: `https://ddragon.leagueoflegends.com/cdn/10.10.3216176/img/profileicon/${
                    p.profileIconId
                  }.png`,
                }))}
              imageStyle={{ borderColor: 'white', borderWidth: 1 }}
              circleSize={15}
            />
          </FeatureParticipants>
        </FeatureFlex>
        <ChallengeDescription>
          {i18n.languages[0].includes('es') && excerpt ? excerpt_es : excerpt}
        </ChallengeDescription>
      </FeatureCardBody>
    </FeatureCardContainer>
  );
};
const FeatureCardContainer = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
const FeatureCardImg = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px 5px 0 0;
`;
const FeatureParticipants = styled.div`
  width: 135px;
`;
const FeatureCenter = styled.div`
  width: 100%;
  background: #0f1425;
  color: #fff;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const FeatureFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;
const FeatureCardBody = styled.div`
  padding: 1rem;
  width: 100%;
  min-height: 100px;
  border: 1px solid #27314d;
  border-top: 0;
  border-radius: 5px;
`;
const ChallengeTitle = styled.h2`
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #fff;
`;
const ChallengeDescription = styled.h2`
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 2rem;
  text-align: left;
  margin-bottom: 1rem;
`;
export default FeatureCard;