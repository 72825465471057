import styled from "styled-components";

export const LayoutContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-template-areas: "nav content" "footer footer";
`;

export const LayoutContent = styled.div`
  width: 100%;
  min-height: 100vh;
  grid-area: content;
`;

export const Footer = styled.footer`
  width: 100%;
  height: 30px;
  background: #0f1425;
  display: flex;
  grid-area: footer;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #fff;
`;
