import React, { useEffect, useState, useContext } from "react";
import { View, StatusBar, ScrollView, Platform } from "react-native";
import { Layout, Text, Spinner, Button } from "@ui-kitten/components";
import FeaturedChallengeCard from "./extra/FeaturedChallengeCard";
import ChallengeCard from "./extra/ChallengeCard";
//import PersonalChallenge from './extra/PersonalChallenge';
import SummonerInfoHeader from "../../components/SummonerInfoHeader";
import FirebaseService, { AuthContext } from "../../services/firebase";
import AdsBanner from "../../components/AdsBanner";
import OneSignal from "react-native-onesignal";
import * as Analytics from "expo-firebase-analytics";
import get from "lodash/get";
import i18n from "../../i18n";
import ModalWinner from "../../components/ModalWinner";

const Challenges = ({ navigation }) => {
  const [currentChallenges, setCurrentChallenges] = useState([]);
  const [nextChallenges, setNextChallenges] = useState([]);
  const { summoner, isSettedSummoner } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [loadingNextChallenge, setLoadingNextChallenge] = useState(false);

  const onNotificationOpened = (openResult: any) => {
    const body = get(openResult, "notification.payload.additionalData", {});
    switch (body.type) {
      case "CHALLENGE":
        onChallengeNotification(body.id);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const summonerCreated = async () => {
      if (isSettedSummoner) {
        await Analytics.logEvent("succesfull register", {
          purpose: "Successful Summoner registered",
        });
      }
    };

    summonerCreated();
    OneSignal.addEventListener("opened", onNotificationOpened);
    return () => {
      OneSignal.removeEventListener("opened", onNotificationOpened);
    };
  }, []);

  const onChallengeNotification = async (challengeId: number) => {
    if (challengeId) {
      const getChallengeResponse = await FirebaseService.getChallenge(
        challengeId
      );
      navigation.navigate({
        name: "ChallengeDetails",
        params: { challenge: getChallengeResponse.data },
      });
    }
  };

  useEffect(() => {
    if (summoner) {
      OneSignal.setExternalUserId(summoner.email);
    } else {
      OneSignal.removeExternalUserId();
    }
  }, [summoner]);

  useEffect(() => {
    (async function () {
      const {
        data: { data: currentChallengesData },
      } = await FirebaseService.getCurrentChallenges();
      setCurrentChallenges(currentChallengesData);
    })();
  }, []);
  useEffect(() => {
    (async function () {
      try {
        setLoadingNextChallenge(true);
        const {
          data: { data: nextChallengesData },
        } = await FirebaseService.getNextChallenges();
        setNextChallenges(nextChallengesData);
        setLoadingNextChallenge(false);
      } catch (error) {
        setLoadingNextChallenge(false);
      }
    })();
  }, []);
  return (
    <>
      <SummonerInfoHeader />
      <StatusBar barStyle="light-content" />

      <Layout level="2" style={{ flex: 1, paddingHorizontal: 34 }}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Text category="s1" style={{ marginBottom: 12, marginTop: 16 }}>
            {i18n.t("challenges.currentChallenge")}
          </Text>
          {currentChallenges.length ? (
            currentChallenges.map((challenge) => (
              <FeaturedChallengeCard
                key={challenge.id}
                {...challenge}
                onPress={() =>
                  navigation.navigate({
                    name: "ChallengeDetails",
                    params: { challenge },
                  })
                }
              />
            ))
          ) : (
            <Spinner />
          )}
          <ModalWinner
            data={modalData}
            visible={visible}
            onClose={() => setVisible(!visible)}
          />
          <Text category="s1" style={{ marginBottom: 12, marginTop: 16 }}>
            {i18n.t("challenges.futureChanllenges")}
          </Text>
          {Platform.OS === "web" ? (
            <AdsBanner inArticle marginBottom={20} />
          ) : null}
          {loadingNextChallenge ? (
            <Spinner />
          ) : (
            nextChallenges.map((challenge) => (
              <ChallengeCard
                key={challenge.id}
                {...challenge}
                onPress={() =>
                  navigation.navigate({
                    name: "ChallengeDetails",
                    params: { challenge },
                  })
                }
              />
            ))
          )}
          {nextChallenges.length === 0 && !loadingNextChallenge && (
            <Text category="s1" style={{ color: "#fff" }}>
              {i18n.t("challenges.notChallenges")}
            </Text>
          )}
          <View style={{ height: 20 }} />

          <View style={{ height: 20 }} />
        </ScrollView>
      </Layout>
      {Platform.OS === "web" ? null : (
        <AdsBanner style={{ backgroundColor: "#101426" }} />
      )}
    </>
  );
};

export default Challenges;
