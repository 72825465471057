import React from "react";
import styled from "styled-components";
import { Image, View } from "react-native";
import get from "lodash/get";
import useComponentSize from "../../hooks/useComponentSize";
import badge from "../../assets/Marcos/bronce.png";
import { BorderWrapper } from "./Border";

const Bronce = ({ children, activeUser }) => {
  const [size, onLayout] = useComponentSize();
  return (
    <BorderWrapper onLayout={onLayout} color="#cd7f32" activeUser={activeUser}>
      <Badge
        source={badge}
        style={{ position: "absolute", left: get(size, "width", 1) / 2 - 25 }}
      />
      {children}
    </BorderWrapper>
  );
};

const Badge = styled(Image)`
  position: absolute;
  width: 50px;
  height: 30px;
  top: -15px;
  left: 0;
`;

export default Bronce;
