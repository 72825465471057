import React, { useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import {
  TopBarContainer,
  FillLink,
  OutFillLInk,
  Links,
  UserWrapper,
  UserImg,
  UserName,
  UserLevel,
  SelectInput,
} from './TopBar.style';
import useCurrentUser from '../../hooks/useCurrentUser';
import Button from '../Button/Button';
import FirebaseService from '../../services/firebase.ts';
import useSummoner from '../../hooks/useSummoner';
import i18n from '../../i18n/i18web';

const Languages = [{ text: 'ES', value: 'es' }, { text: 'EN', value: 'en' }];

const TopBar = () => {
  const { id, name, img } = useCurrentUser();
  const { summoner } = useSummoner(id);
  const [language, setLanguage] = useState(i18n.languages[0]);
  const { t } = useTranslation(['global', '']);

  const onChangeLanguage = e => {
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  return (
    <TopBarContainer>
      {id && (
        <>
          <UserWrapper>
            <UserImg src={img} />
            <UserLevel>{summoner?.summonerLevel}</UserLevel>
            <UserName>{name}</UserName>
          </UserWrapper>
          <div style={{ display: 'flex' }}>
            <SelectInput
              value={language.value}
              defaultValue={i18n.languages[0]}
              onChange={onChangeLanguage}
            >
              {Languages.map((lang) => (
                <option key={lang.value} value={lang.value}>
                  {lang.text}
                </option>
              ))}
            </SelectInput>
            <Button
              background="transparent"
              color="#fff"
              onClick={() => FirebaseService.signOut()}
              style={{ marginLeft: 10 }}
            >
              {t('logout')}
              <FiLogOut size={20} color="#fff" style={{ marginLeft: 10 }} />
            </Button>
          </div>
        </>
      )}
      {!id && (
        <>
          <SelectInput value={language.value} onChange={onChangeLanguage}>
            {Languages.map((lang) => (
              <option key={lang.value} value={lang.value}>
                {lang.text}
              </option>
            ))}
          </SelectInput>

          <Links>
            <OutFillLInk to="/login">{t('login')}</OutFillLInk>
            <FillLink to="/signup">{t('signup')}</FillLink>
          </Links>
        </>
      )}
    </TopBarContainer>
  );
};

export default TopBar;
