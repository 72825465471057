import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import { Spinner, Text } from '@ui-kitten/components';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import useGetCurrentChallenges from '../hooks/useGetCurrentChallenges';
import { WrapperPageContent, SectionName, WrapperAds } from '../styles/Shared';
import useGetFutureChallenges from '../hooks/useGetFutureChallenge';
import FeatureCard from '../components/FeatureCard';
import ChallengeCard from '../components/ChallengeCard';
import useCurrentUser from '../hooks/useCurrentUser';
import useSummoner from '../hooks/useSummoner';
import AdsBanner from '../components/AdsBanner.web.tsx';
import { generateChallengeSlug } from '../utils/index';

const HistoryCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 10px;
`;
const Challenge = () => {
  const {
    nextChallenges,
    loading: loadingUpcomingChallenges,
  } = useGetFutureChallenges();
  const { challenges, loading: loadingChallenges } = useGetCurrentChallenges();
  const { id } = useCurrentUser();
  const summoner = useSummoner(id);
  const { t } = useTranslation(['challenges']);

  useEffect(() => {
    if (!summoner) {
      navigate('/create-summoner');
    }
  }, [summoner]);

  const onClickChallenge = (challenge) => navigate(`/challenge/${generateChallengeSlug(challenge)}`);

  return (
    <Layout>
      <WrapperPageContent>
        <SectionName>{t('currentChallenge')}</SectionName>
        {loadingChallenges ? (
          <Spinner status="basic" />
        ) : (
          <HistoryCardContainer>
            {challenges.map((challenge) => (
              <FeatureCard
                key={challenge.id}
                {...challenge}
                onClick={() => onClickChallenge(challenge)}
              />
            ))}
          </HistoryCardContainer>
        )}
        <WrapperAds>
          {Platform.OS === 'web' ? (
            <AdsBanner inArticle marginBottom={20} />
          ) : null}
        </WrapperAds>
        <SectionName>{t('futureChanllenges')}</SectionName>
        {loadingUpcomingChallenges ? (
          <Spinner status="basic" />
        ) : (
          <HistoryCardContainer>
            {nextChallenges.length === 0 ? (
              <Text category="s1" style={{ color: '#fff' }}>
                {t('notChallenges')}
              </Text>
            ) : (
              nextChallenges.map((nextChallenge) => (
                <ChallengeCard
                  {...nextChallenge}
                  key={nextChallenge.id}
                  onClick={() => onClickChallenge(nextChallenge)}
                />
              ))
            )}
          </HistoryCardContainer>
        )}
      </WrapperPageContent>
    </Layout>
  );
};

export default Challenge;
