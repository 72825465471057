import React from "react";
import styled from "styled-components";
import { Image, View } from "react-native";
import get from "lodash/get";
import useComponentSize from "../../hooks/useComponentSize";
import silverLeft from "../../assets/Marcos/plata-2.png";
import silverRight from "../../assets/Marcos/plata-3.png";
import badge from "../../assets/Marcos/plata-1.png";
import { BorderWrapper } from "./Border";

const Silver = ({ children, activeUser }) => {
  const [size, onLayout] = useComponentSize();
  return (
    <BorderWrapper onLayout={onLayout} color="#A9A9A9" activeUser={activeUser}>
      <Left source={silverLeft} style={{ resizeMode: "contain" }} />
      <Badge
        source={badge}
        style={{ position: "absolute", left: get(size, "width", 1) / 2 - 25 }}
      />
      <Right source={silverRight} style={{ resizeMode: "contain" }} />
      {children}
    </BorderWrapper>
  );
};

const Badge = styled(Image)`
  position: absolute;
  width: 50px;
  height: 30px;
  top: -15px;
  left: 0;
`;

const Left = styled(Image)`
  position: absolute;
  width: 50px;
  height: 30px;
  top: -9px;
  left: -20px;
`;

const Right = styled(Image)`
  position: absolute;
  width: 50px;
  height: 30px;
  top: -9px;
  right: -20px;
`;

export default Silver;
