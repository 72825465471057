import React from 'react';
import {
  Image,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
  Share,
} from 'react-native';
import { Text } from '@ui-kitten/components';
import formatDistance from 'date-fns/formatDistance';
import * as Localization from 'expo-localization';
import { BackIcon, ShareIcon } from '../../../components/Icons';
import { useSafeArea } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import { es, enUS } from 'date-fns/locale';
import i18n from '../../../i18n';
import { generateChallengeSlug } from '../../../utils/index';

const renderLocale = () =>
  Localization.locale.includes('es')
    ? { locale: es, addSuffix: true }
    : { locale: enUS, addSuffix: true };

const ChallengeDetailsHeader = ({
  title,
  photoURL,
  endDate,
  startDate,
  active,
  title_es = 'por definir',
  excerpt,
  excerpt_es,
  challenge,
}) => {
  const insets = useSafeArea();
  const navigation = useNavigation();
  const onRenderDate = () => {
    const now = Date.now();

    if (now > endDate) {
      return `${i18n.t('date.ended')}  ${formatDistance(
        endDate,
        new Date(),
        renderLocale()
      )}`;
    }

    return active
      ? ` ${i18n.t('date.endsin')} ${formatDistance(
          endDate,
          new Date(),
          renderLocale()
        )}`
      : ` ${i18n.t('date.startsin')} ${formatDistance(
          startDate,
          new Date(),
          renderLocale()
        )}`;
  };

  const onShare = async () => {
    const shareMessage = i18n.t(
      'share.message'
    )
    const shareLink = challenge.shareLink || `https://app.poky.gg/challenges/${generateChallengeSlug(challenge)}`;
    const message = `${shareMessage} ${shareLink}`;
    try {
      await Share.share({
        message,
      });
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <>
      <Image
        style={styles.headerImage}
        source={{
          uri: photoURL,
        }}
      />
      <View style={styles.headerTextContainer}>
        <View style={styles.backButton}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <BackIcon height={30} width={30} tintColor="white" />
          </TouchableOpacity>
        </View>
        <View style={styles.headerContent}>
          <View>
            <Text category="c2">
              {Localization.locale.includes('es') ? title_es : title}
            </Text>
          </View>
          <Text category="c1">
            {Localization.locale.includes('es') ? excerpt_es : excerpt}
          </Text>
          <Text category="c1">{onRenderDate()}</Text>
        </View>
        <View style={styles.shareButton}>
          <TouchableOpacity onPress={onShare}>
            <ShareIcon height={30} width={30} tintColor="white" />
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  backButton: {
    minWidth: 40,
    width: '10%',
    alignItems: 'flex-start',
  },
  shareButton: {
    minWidth: 40,
    width: '10%',
    alignItems: 'flex-end',
  },
  headerContent: {
    flex: 1,
    maxWidth: '80%',
    alignItems: 'center',
  },
  headerImage: {
    height: 240,
    resizeMode: 'cover',
  },
  headerTextContainer: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    backgroundColor: 'rgba(16,20,38,0.9)',
    marginTop: Platform.OS == 'ios' ? -77 : -79,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default ChallengeDetailsHeader;
