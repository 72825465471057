import { useState, useEffect } from 'react';
import { getChampionInfo } from '../services/champions.ts';

const useGetChallengesChampions = (champs = []) => {
  const [challengeChamps, setChallengeChamps] = useState([]);

  useEffect(() => {
    const getChamps = () => {
      const champsArr = champs.map(champ => {
        const champData = getChampionInfo(champ);
        return {
          ...champData,
          img: `https://ddragon.leagueoflegends.com/cdn/10.11.1/img/champion/${
            champData.id
            }.png`,
        };
      });
      setChallengeChamps(champsArr);
    };
    if (champs.length > 0) {
      getChamps();
    }
  }, [champs]);

  return {
    challengeChamps,
  };
};

export default useGetChallengesChampions;
