import React from 'react';
import styled from 'styled-components';
import { Text, Avatar } from '@ui-kitten/components';
import { GiPointySword } from 'react-icons/gi';
import openURL from '../services/openURL.ts';

import { getChampionInfo } from '../services/champions.ts';
import BorderComp from './BorderLeaderboardWeb/Border';

const LeaderBoardList = ({ data = [], challenge, userName }) => {
  const getPrizeFromArray = (prizes, index) => {
    const prize = prizes[index];
    return prize ? `${prize.value} ${prize.type}` : null;
  };

  const getPrize = (challenge, index) => {
    if (!challenge || !challenge.prizes) {
      return null;
    }

    return getPrizeFromArray(challenge.prizes, index);
  };

  const onItemPress = (gameId, region) => {
    let regionSlug = 'lan';
    switch (region) {
      case 'LA1':
        regionSlug = 'lan';
        break;
      case 'LA2':
        regionSlug = 'las';
        break;
      case 'NA1':
        regionSlug = 'na';
        break;
      default:
        break;
    }
    return `https://www.leagueofgraphs.com/match/${regionSlug}/${gameId}`;
  };

  const renderPrizes = (user, index) => {
    const prize = getPrize(challenge, index);
    return (
      <Right>
        <Text category="c1">
          {typeof user.score === 'number' ? `${user.score}` : 'None'}
        </Text>
        <GiPointySword style={{ marginLeft: '10' }} color="#9C11A3" size={25} />
        <Text>{prize && user.score ? ` / ${prize}` : ''}</Text>
      </Right>
    );
  };

  const renderAvatar = (user, index) => {
    const championInfo = user.championId
      ? getChampionInfo(user.championId)
      : null;

    return (
      <>
        <Text category="c1" style={{ marginRight: 10 }}>
          {typeof user.score === 'number' ? `${index + 1}º` : '?'}
        </Text>
        <Avatar
          size="small"
          source={{
            uri: `https://ddragon.leagueoflegends.com/cdn/10.10.3216176/img/profileicon/${
              user.profileIconId
              }.png`,
          }}
        />
        {championInfo ? (
          <Avatar
            size="small"
            style={{ marginLeft: -15 }}
            source={{
              uri: `https://ddragon.leagueoflegends.com/cdn/10.11.1/img/champion/${
                championInfo.id
                }.png`,
            }}
          />
        ) : null}
      </>
    );
  };

  const renderPosition = (user, position) => {
    const championInfo = user.championId
      ? getChampionInfo(user.championId)
      : null;

    return (
      <BorderComp position={position + 1} key={position}>
        <a
          href={user.gameId ? onItemPress(user.gameId, user.region) : null}
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: '100%' }}
        >
          <LeaderboardItem
            activeUser={user.name === userName}
            position={position + 1}
          >
            <Left>
              {renderAvatar(user, position)}
              <Names>
                <UserName>{user.name}</UserName>
                <span>{championInfo ? championInfo.name : null}</span>
              </Names>
            </Left>
            {renderPrizes(user, position)}
          </LeaderboardItem>
        </a>
      </BorderComp>
    );
  };
  return (
    <Leaderboard>{data.map((user, i) => renderPosition(user, i))}</Leaderboard>
  );
};

const Leaderboard = styled.div`
  height: 55vh;
  overflow-y: auto;
  padding: 1rem;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ccc;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

const LeaderboardItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  ${props => props.activeUser && `background: #3c25fa`};
  border-radius: 5px;
  ${props =>
    props.position >= 7 &&
    props.activeUser &&
    `
    position: sticky;
    bottom: -10px;
  `};
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
`;

const Names = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-size: 1.1rem;
`;

const UserName = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
`;

export default LeaderBoardList;
