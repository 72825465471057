import { useState, useEffect } from 'react';
import FirebaseService from '../services/firebase.ts';

const useSummoner = id => {
  const [summoner, setSummoner] = useState({});
  useEffect(() => {
    const getSummoner = async () => {
      try {
        const { data } = await FirebaseService.getSummonerByUID(id);
        setSummoner(data);
      } catch (error) {
        console.log(error.message);
      }
    };

    if (id) {
      getSummoner();
    }
  }, [id]);
  return {
    summoner,
  };
};

export default useSummoner;
