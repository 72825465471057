import React from "react";
import { StyleSheet, View, Image, Platform } from "react-native";
import { Text, Card } from "@ui-kitten/components";
import FacePile from "react-native-face-pile";
import Color from "color";
//import CoinsLabel from '../../../components/CoinsLabel';
import formatDistance from "date-fns/formatDistance";
import * as Localization from "expo-localization";
import i18n from "../../../i18n";
import { es, enUS } from "date-fns/locale";

const renderLocale = () =>
  Localization.locale.includes("es")
    ? { locale: es, addSuffix: true }
    : { locale: enUS, addSuffix: true };

export const CustomHeader = ({ photoURL, endDate }) => (
  <>
    <Image
      style={styles.headerImage}
      source={{
        uri: photoURL,
      }}
    />
    <View style={styles.headerTextContainer}>
      {/* <CoinsLabel prefix="Win" coins="200" /> */}
      <Text style={styles.headerText} category="c2">
        {endDate
          ? `${i18n.t("date.endsin")} ${formatDistance(
              endDate,
              new Date(),
              renderLocale()
            )}`
          : null}
      </Text>
    </View>
  </>
);

const FeatureChallenge = ({
  onPress,
  photoURL,
  participants = [],
  endDate,
  title,
  excerpt,
  title_es = "Por definir",
  excerpt_es = "Por definir",
}) => {
  return (
    <Card
      header={() => CustomHeader({ photoURL, endDate })}
      style={{ borderColor: "#2e3a59", marginBottom: 10 }}
      onPress={onPress}
    >
      <View style={styles.challengeInfo}>
        <Text style={styles.challengeTitle} category="c2">
          {Localization.locale.includes("es")
            ? title_es.toUpperCase()
            : title.toUpperCase()}
        </Text>
        <FacePile
          numFaces={4}
          faces={Object.values(participants)
            .slice(0, 5)
            .map((p) => ({
              id: p.name,
              imageUrl: `https://ddragon.leagueoflegends.com/cdn/10.10.3216176/img/profileicon/${p.profileIconId}.png`,
            }))}
          imageStyle={{ borderColor: "white", borderWidth: 1 }}
          circleSize={15}
        />
      </View>
      <Text category="c1">
        {Localization.locale.includes("es") && excerpt ? excerpt_es : excerpt}
      </Text>
    </Card>
  );
};

export default FeatureChallenge;

const styles = StyleSheet.create({
  challengeInfo: {
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: -10,
    marginBottom: 18,
  },
  headerText: {},
  challengeTitle: {
    maxWidth: "80%",
    marginBottom: 10,
  },
  headerImage: {
    height: 165,
    resizeMode: "cover",
  },
  headerTextContainer: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    backgroundColor: "rgba(16,20,38,0.9)",
    marginTop: Platform.OS == "ios" ? -48 : -48,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
