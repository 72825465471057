import React from 'react';
import styled from 'styled-components';
import goldLeft from '../../assets/Marcos/Oro-2.png';
import goldRight from '../../assets/Marcos/Oro-3.png';
import badge from '../../assets/Marcos/Oro-1.png';
import { Border } from './Border';

const GoldBorder = ({ children }) => (
  <Border color="#D4AF37">
    <LeftGold />
    <CenterBadge />
    <RightGold />
    {children}
  </Border>
);

export const LeftGold = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;
  left: -8px;
  top: -8px;
  background: url(${goldLeft});
  background-size: cover;
`;

export const RightGold = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;
  right: -8px;
  top: -8px;
  background: url(${goldRight});
  background-size: cover;
`;

export const CenterBadge = styled.div`
  height: 30px;
  width: 80px;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: -15px;
  background: url(${badge});
  background-size: cover;
`;

export default GoldBorder;
