import { useState, useEffect } from 'react';
import FirebaseService from '../services/firebase.ts';

const useGetPastChallenges = () => {
  const [pastChallenges, setPastChalleges] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getChallenges = async () => {
      try {
        setLoading(true);
        const { data } = await FirebaseService.getHistoryChallenges();
        setPastChalleges(data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getChallenges();
  }, []);

  return { pastChallenges, loading };
};

export default useGetPastChallenges;
