import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  StyleSheet,
  View,
  Image,
  Platform,
  TouchableOpacity,
  RefreshControl,
} from 'react-native';
import { Text, List, ListItem, Avatar, Spinner } from '@ui-kitten/components';
import { LeaderBoard, Challenges } from '../../../types';
import openURL from '../../../services/openURL';
import { getChampionInfo } from '../../../services/champions';
import AdsBanner from '../../../components/AdsBanner';
import Border from '../../../components/BorderLeaderboard/Border';
import { AuthContext } from '../../../services/firebase';
import { ScrollView } from 'react-native-gesture-handler';
// import { ActionSheetCustom as ActionSheet } from 'react-native-actionsheet';
import { MaterialIcons } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import i18n from '../../../i18n/';

type Props = {
  data: LeaderBoard | undefined;
  challenge: Challenges;
  refresh: Function;
};
const onItemPress = (gameId, region): void => {
  let regionSlug = 'lan';
  switch (region) {
    case 'LA1':
      regionSlug = 'lan';
      break;
    case 'LA2':
      regionSlug = 'las';
      break;
    case 'NA1':
      regionSlug = 'na';
      break;
    default:
      break;
  }
  openURL(`https://www.leagueofgraphs.com/match/${regionSlug}/${gameId}`);
};

const getPrize = (challenge, index) => {
  if (!challenge || !challenge.prizes) {
    return null;
  }

  return Array.isArray(challenge.prizes)
    ? getPrizeFromArray(challenge.prizes, index)
    : getPrizeFromObject(challenge.prizes, index);
};

const getPrizeFromObject = (prizes, index) => {
  index = index + 1;
  if (index === 1) {
    return prizes.first;
  } else if (index === 2) {
    return prizes.second;
  } else if (index === 3) {
    return prizes.third;
  } else {
    return null;
  }
};

const getPrizeFromArray = (prizes, index) => {
  const prize = prizes[index];
  return prize ? `${prize.value} ${prize.type}` : null;
};

const LeaderboardList = ({
  data = [],
  challenge,
  refresh,
  showActionSheetWithOptions,
}: Props) => {
  const { summoner } = useContext(AuthContext);
  const [onRefreshing, setOnRefreshing] = useState(false);
  const refSheet = useRef(null);

  const onRefresh = async () => {
    try {
      setOnRefreshing(true);

      await refresh();
      setOnRefreshing(false);
    } catch (error) {
      console.log(error);
      setOnRefreshing(false);
    }
  };

  const renderItemAccessory = useCallback(
    (style, index) => {
      const prize = getPrize(challenge, index + 3);
      return (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text category="c1" style={style}>
            {typeof data[index + 3]?.score === 'number'
              ? `${data[index + 3]?.score}`
              : 'None'}
          </Text>
          <Image
            style={styles.badgeImage}
            source={{
              uri: `https://ddragon.leagueoflegends.com/cdn/5.5.1/img/ui/score.png`,
            }}
          />
          <Text>{prize && data[index + 3]?.score ? ` / ${prize}` : ''}</Text>
        </View>
      );
    },
    [data]
  );

  const renderItemIcon = useCallback(
    (style, index) => {
      const championInfo = data[index + 3]?.championId
        ? getChampionInfo(data[index + 3]?.championId)
        : null;
      return (
        <>
          <Text category="c1">
            {typeof data[index + 3]?.score === 'number' ? `${index + 4}º` : '?'}
          </Text>
          <Avatar
            size="small"
            style={{ marginHorizontal: style.marginHorizontal }}
            source={{
              uri: `https://ddragon.leagueoflegends.com/cdn/10.21.1/img/profileicon/${
                data[index + 3]?.profileIconId
              }.png`,
            }}
          />
          {championInfo ? (
            <Avatar
              size="small"
              style={{ marginLeft: -15 }}
              source={{
                uri: `https://ddragon.leagueoflegends.com/cdn/10.21.1/img/champion/${championInfo.id}.png`,
              }}
            />
          ) : null}
        </>
      );
    },
    [data]
  );

  const showActionSheet = () => {
    refSheet.current.show();
  };

  // this function should render the elements from 3 to ...n
  const renderItem = ({ item, index }) => {
    const championInfo = item.championId
      ? getChampionInfo(item.championId)
      : null;

    return (
        <ListItem
          title={item.name}
          description={championInfo ? championInfo.name : null}
          icon={renderItemIcon}
          accessory={renderItemAccessory}
          onPress={() =>
            item?.urlStream
              ? showActionSheet()
              : item.gameId
              ? onItemPress(item.gameId, item.region)
              : null
          }
          style={
            summoner?.name === item.name && {
              backgroundColor: '#5731fe',
              borderRadius: 5,
            }
          }
        />
    );
  };

  const renderOption = (text, icon) => (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {icon === 'computer' && (
        <MaterialIcons
          name={icon}
          size={16}
          color="#3366ff"
          style={{ marginRight: 10 }}
        />
      )}

      {icon === 'stream' && (
        <Feather
          name="cast"
          size={16}
          color="#3366ff"
          style={{ marginRight: 10 }}
        />
      )}
      <Text style={{ color: '#3366ff' }}>{text}</Text>
    </View>
  );

  const options = [
    renderOption(i18n.t('challengeDetails.goToMatch'), 'computer'),
    renderOption(i18n.t('challengeDetails.goToStream'), 'stream'),
    <Text style={{ color: '#3366ff' }}>
      {i18n.t('challengeDetails.cancel')}
    </Text>,
  ];

  const renderList = () => (
    <ScrollView
      refreshControl={
        <RefreshControl refreshing={onRefreshing} onRefresh={onRefresh} />
      }
    >
      {data.slice(0, 3).map((item, index) => {
        const championInfo = data[index]?.championId
          ? getChampionInfo(data[index]?.championId)
          : null;
        const prize = getPrize(challenge, index);

        return (
          <React.Fragment key={item.name}>
            <TouchableOpacity
              onPress={() =>
                item.urlStream
                  ? showActionSheet()
                  : item.gameId
                  ? onItemPress(item.gameId, item.region)
                  : null
              }
            >
              <Border
                position={index + 1}
                activeUser={summoner?.name === item.name}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    category="c1"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    {typeof data[index + 1]?.score === 'number'
                      ? `${index + 1}º`
                      : '?'}
                  </Text>
                  <Avatar
                    size="small"
                    source={{
                      uri: `https://ddragon.leagueoflegends.com/cdn/10.10.3216176/img/profileicon/${data[index]?.profileIconId}.png`,
                    }}
                  />
                  {championInfo ? (
                    <Avatar
                      size="small"
                      style={{ marginLeft: -15 }}
                      source={{
                        uri: `https://ddragon.leagueoflegends.com/cdn/10.11.1/img/champion/${championInfo.id}.png`,
                      }}
                    />
                  ) : null}
                  <View style={{ marginLeft: 10 }}>
                    <Text style={{ fontSize: 12 }}>{item.name}</Text>
                    <Text style={{ fontSize: 12 }}>
                      {championInfo ? championInfo.name : null}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 5,
                  }}
                >
                  <Text category="c1">
                    {typeof data[index]?.score === 'number'
                      ? `${data[index]?.score}`
                      : 'None'}
                  </Text>
                  <Image
                    style={styles.badgeImage}
                    source={{
                      uri: `https://ddragon.leagueoflegends.com/cdn/5.5.1/img/ui/score.png`,
                    }}
                  />
                  <Text>
                    {prize && data[index]?.score ? ` / ${prize}` : ''}
                  </Text>
                </View>
              </Border>
            </TouchableOpacity>
            {/* <ActionSheet
              ref={refSheet}
              // title={"Which one do you like ?"}
              options={options}
              cancelButtonIndex={2}
              destructiveButtonIndex={1}
              onPress={index => {
                if (index === 0) {
                  item.gameId ? onItemPress(item.gameId, item.region) : null;
                }
              }}
            /> */}
          </React.Fragment>
        );
      })}
      <View style={styles.wrapperAd}>
        <AdsBanner style={{ backgroundColor: '#101426' }} />
      </View>
      <List
        data={data.slice(3)}
        renderItem={renderItem}
        style={[styles.background, styles.leaderboardSpacing]}
      />
    </ScrollView>
  );

  return data ? (
    <>
      {Platform.OS === 'web' ? (
        <div
          style={{
            height: '44vh',
            overflowY: 'auto',
          }}
        >
          {renderList()}
        </div>
      ) : (
        renderList()
      )}
    </>
  ) : (
    <View style={styles.loadingContainer}>
      <Spinner size="giant" />
    </View>
  );
};
export default LeaderboardList;

const styles = StyleSheet.create({
  leaderboardSpacing: { paddingTop: 10, paddingBottom: 20 },
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    backgroundColor: '#222B45',
  },
  badgeImage: {
    height: 26,
    width: 26,
    marginTop: 10,
  },
  button: {
    marginBottom: 15,
  },
  wrapperAd: {
    marginTop: 20,
    width: '100%',
    marginBottom: 20,
  },
});
