import React from "react";
import useComponentSize from "../hooks/useComponentSize.js";
import { View, Image } from "react-native";
import styled from "styled-components";
import get from "lodash/get";
import ashe from "../assets/ashe.jpg";
import { Spinner } from "@ui-kitten/components";

const LoadingOnAuth = () => {
  const [size, onLayout] = useComponentSize();

  return (
    <LoadingContainer onLayout={onLayout}>
      <ImageLoading
        source={ashe}
        style={{ height: get(size, "height", 900), resizeMode: "cover" }}
      />
      <Spinner status="basic" size="giant" />
    </LoadingContainer>
  );
};

const LoadingContainer = styled(View)`
  flex: 1;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const ImageLoading = styled(Image)`
  width: 100%;
  flex: 1;
  position: absolute;
  top: 0;
`;

export default LoadingOnAuth;
