import React from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import styledWeb from 'styled-components';
import isPast from 'date-fns/isPast';
import { useTranslation } from 'react-i18next';
import { es, enUS } from 'date-fns/locale';
import Button from './Button/Button';
import i18n from '../i18n/i18web';

const renderLocale = () =>
  i18n.languages[0].includes('es')
    ? { locale: es, addSuffix: true }
    : { locale: enUS, addSuffix: true };

const ChallengeCard = ({ onClick, title, photoURL, startDate, endDate }) => {
  const { t } = useTranslation(['history', 'date']);
  const dateText = isPast(endDate)
    ? t('date:ended')
    : isPast(startDate)
    ? t('date:endsin')
    : t('date:startsin');

  return (
    <ChallengeCardContainer>
      <ChallengeCardImg src={photoURL} />
      <ChallengeCardBody>
        <ChallengeInfo>
          <ChallengeTitle>{title}</ChallengeTitle>
          {/* <span>
            {startDate
              ? `${dateText} ${formatDistance(startDate, new Date(), renderLocale())}`
              : null}
          </span> */}
        </ChallengeInfo>
        <Button onClick={onClick} background="#3c25fa" color="#fff">
          {t('buttonDetail')}
        </Button>
      </ChallengeCardBody>
    </ChallengeCardContainer>
  );
};

const ChallengeCardContainer = styled.View`
  width: 100%;
  height: 100%;
`;
const ChallengeCardImg = Platform.OS === 'web' ? styledWeb.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px 5px 0 0;
`: styled.Image`
width: 100%;
height: 180px;
object-fit: cover;
object-position: center;
border-radius: 5px 5px 0 0;
` ;

const ChallengeCardBody = styled.View`
  width: 100%;
  background: #0f1425;
  color: #fff;
  min-height: 50px;
  padding: ${Platform.OS === 'web' ? '1rem 2rem' : '14px 28px'};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


const ChallengeTitle = Platform.OS === 'web' ? styledWeb.h2`
font-size: 1.2rem;
text-transform: uppercase;
margin-bottom: 1rem;
color: #fff;
` : styled.Text`
font-size: 16px;
text-transform: uppercase;
margin-bottom: 14px;
color: #fff;
`;

const ChallengeInfo = styled.View`
  display: flex;
  flex-direction: column;
`;

export default ChallengeCard;
