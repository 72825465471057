import React from "react";
import { WebView } from "react-native-webview";
import { View } from "react-native";
import styled from "styled-components";

const VideoPlayer = ({
  source = "https://www.youtube.com/embed/aR-KAldshAE",
  marginRight = 0,
}) => (
  <Video marginRight={marginRight}>
    <WebView source={{ uri: source }} />
  </Video>
);

const Video = styled(View)`
  margin-top: 20px;
  width: 170px;
  height: 100px;
  margin-right: ${(props) => props.marginRight};
`;

export default VideoPlayer;
