export default {
  challenges: {
    currentChallenge: 'CURRENT CHALLENGE',
    futureChanllenges: 'FUTURE CHALLENGE',
    notChallenges: 'No Future Challenges available',
  },
  nav: {
    history: 'HISTORY',
    challenge: 'CHALLENGE',
    profile: 'PROFILE',
  },
  history: {
    pastChallenge: 'PAST CHALLENGES',
    buttonDetail: 'SEE DETAILS',
    refresh: 'REFRESH',
    refreshing: 'REFRESING...',
  },
  profile: {
    profile: 'Profile',
    share: 'Share',
    stream: 'Stream Channel',
    rate: 'Rate us',
    terms: 'Terms of use and Conditions',
    rules: 'Challenge generic rules details',
    logout: 'Log out',
  },
  stream: {
    inputPlaceholder: 'Add your Twitch or Facebook channel',
    description:
      'Do you want PokyGG to show your best plays and game plays on our stream channels ? Please add your Twitch or Facebook gaming URL.',
    button: 'UPDATE',
    inputLabel: 'URL Channel',
  },
  challengeDetails: {
    leaderboard: 'LEADERBOARD',
    description: 'DESCRIPTION',
    rules: 'PRIZES & RULES',
    lastUpdate: 'Last update:',
    rule: 'Rules',
    join: 'JOIN',
    loading: 'LOADING...',
    requirements: 'REQUIREMENTS',
    win: 'Win the match',
    required: 'Required',
    notRequired: 'Not Required',
    start: 'START',
    ends: 'END',
    prizes: 'Prizes',
    place: 'Place',
    first: 'First',
    second: 'Second',
    third: 'Third',
    champions: 'CHAMPIONS',
    blocked: 'Blocked',
    allowed: 'Allowed',
    goToMatch: 'Go to match details',
    goToStream: 'Go to Stream Channel',
    cancel: 'Cancel',
  },
  login: {
    login: 'Log in to your account',
    email: 'Email',
    password: 'Password',
    loginBtn: 'Log in',
    signupLink: "Don't have an account? Sign Up",
    loginInfo:
      'POKY.GG isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone officially involved in producing or managing Riot Games properties. Riot Games, and all associated properties are trademarks or registered trademarks of Riot Games, Inc.',
    loginInfo2:
      'Apple is not a sponsor nor involved in any way with the POKY.GG challenges.',
    forgotPassword: 'Forgot your password?',
    resetPassword: 'Reset Your password',
    PasswordReseted: 'An Email has been sent to: ',
    PasswordReseted2: ' to retrieve your password',
  },
  signup: {
    signup: 'Sign up and enjoy the challenges',
    checkboxTerms: 'I read and agree to',
    terms: 'Terms of use and conditions',
    checkboxAge: 'I am at least 17+ years old',
    signupBtn: 'Sign up',
    loginLink: 'Already have an account? Log in',
    password: 'Password',
    passwordErr: 'Password should be at least 6 characters',
  },
  global: {
    notAuth: 'Session not started',
    signup: 'SIGNUP',
    login: 'LOGIN',
    logout: 'LOGOUT',
  },
  summoner: {
    description: '¡You are almost ready!, currently we are only supporting LAN, LAS and NA users, so just be sure that you are using your right summoner name',
    name: 'Summoner Name',
    server: 'Server',
    loading: 'Loading',
    userAlreadyTaken: 'This Summoner is already taken!',
    confirm: 'Confirm',
    logout: `Don't Want to continue? Log out`,
  },
  date: {
    ended: 'Ended',
    endsin: 'Ends',
    startsin: 'Starts ',
  },
  poppuWinner: {
    button: "A Poky's member will contact you soon!",
  },
  joinPopup: {
    title: 'READY TO JOIN ?',
    description1: 'Do you want to spend ',
    description2: ' Poky coins to join to this challenge?',
    userPokys: 'You have: ',
    button: 'YES, JOIN !',
    titlev2: 'OH NO, YOU NEED MORE POKY COINS',
    descriptionv2:
      'Keep playing Free Challenges to earn more Poky coins! Or go to the store and buy a Poky coin package.',
    storeButton: 'Go to store',
    freePokysLink: ' Watch a video and get FREE Pokys',
    wrongLevel:
      'Sorry! You can not join this challenge, you need at least level ',
  },
  errors: {
    inputEmpty: 'Fill in the input',
    emailNotRegister: 'Incorrect credentials',
    wrongPassword: 'Incorrect credentials',
    notSummoner: 'This summoner does not exist',
    emailRegisterd: 'This email is already in use',
    emailIsInvalid: 'This email is invalid',
  },
  share: {
    message: 'Get RP every week by participating in the challenges and help your friends to be in the first places!'
  }
};
