import { useState, useEffect } from 'react';
import * as Analytics from 'expo-firebase-analytics';
import FirebaseService from '../services/firebase.ts';
import useCurrentUser from './useCurrentUser';
import { getFilenameFromURL } from '../utils/index.ts';

const useGetChallengeLeaderboard = id => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const [refreshing, setRefreshing] = useState(false);
  const [joining, setJoining] = useState(false);
  const { name, img } = useCurrentUser();

  useEffect(() => {
    const getChallegeLeaderboard = async () => {
      try {
        setLoading(true);
        const res = await FirebaseService.getLeaderboard(id);
        setLeaderboard(res.leaderboard);
        setLastUpdate(res.lastUpdate);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    getChallegeLeaderboard();
  }, [id]);

  const refreshLeaderboard = async () => {
    setRefreshing(true);
    try {
      const { data } = await FirebaseService.calculateChallengeLeaderboard(id);
      setLeaderboard(data?.leaderboard);
      setLastUpdate(data?.lastUpdate);

      setRefreshing(false);
    } catch (error) {
      setRefreshing(false);
    }
  };

  const onRefresh = async () => {
    await refreshLeaderboard();
    await Analytics.logEvent('LeaderBoard_Refresh', {
      screen: 'Challenge_Detail',
      purpose: 'Request LeaderBoard_Refresh',
      summoner: name,
    });
  };

  const onSubscribeToChallenge = async () => {
    try {
      setJoining(true);
      const { data } = await FirebaseService.subscribeToChallenge(id);
      setJoining(false);
      await Analytics.logEvent('Join_Challenge', {
        screen: 'Challenge_Detail',
        purpose: 'Successful Join a Challenge',
        summoner: name,
      });
      return data.data;
    } catch (error) {
      setJoining(false);
    }
  };

  const setLeaderboardOnSubscribe = async () => {
    try {
      const res = await FirebaseService.getLeaderboard(id);
      setLeaderboard([
        ...res.leaderboard,
        {
          name,
          profileIconId: getFilenameFromURL(img),
        },
      ]);
      setLastUpdate(res.lastUpdate);
      await refreshLeaderboard();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    leaderboard,
    loading,
    lastUpdate,
    onSubscribeToChallenge,
    onRefresh,
    refreshing,
    joining,
    setLeaderboardOnSubscribe,
  };
};

export default useGetChallengeLeaderboard;
