import React, { useState, useEffect } from 'react';
import AuthStackScreens from './AuthStack';
import LoadingScreen from '../screens/auth/LoadingScreen';
import FirebaseService, { AuthContext } from '../services/firebase';
import * as Analytics from 'expo-firebase-analytics';
import { NavigationContainer } from '@react-navigation/native';
import Sentry from '../utils/sentry';
import LoadingOnAuth from '../components/LoadingOnAuth';
import * as SplashScreen from 'expo-splash-screen';

//Degug Analytics with firebase
// Analytics.setDebugModeEnabled(true)
//   .then(() => console.log('Debug mode enable'))
//   .catch(console.warn);
// Gets the current screen from navigation state
const getActiveRouteName = state => {
  const route = state.routes[state.index];

  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route.name;
};

const Root = () => {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState(null);
  const [summoner, setSummoner] = useState(null);
  const routeNameRef = React.useRef();
  const navigationRef = React.useRef();
  const [isSettedSummoner, setIsSettedSummer] = useState(false);
  const [loading, setLoading] = useState(false);
  React.useEffect(() => {
    if (navigationRef && navigationRef.current) {
      const state = navigationRef.current.getRootState();
      // Save the initial route name
      routeNameRef.current = getActiveRouteName(state);
    }
  }, []);

  // Handle user state changes
  async function onAuthStateChanged(user: any) {
    try {
      SplashScreen.preventAutoHideAsync();
      if (user) {
        setUser(user);
        try {
          setLoading(true);
          const summoner = await FirebaseService.getSummonerByUID(user.uid);
          setSummoner(summoner?.data);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      } else {
        setUser(null);
        setSummoner(null);
      }
    } catch (e) {
      // We might want to provide this error information to an error reporting service
      console.warn(e);
    } finally {
      if (initializing) {
        setInitializing(false);
      }
      await SplashScreen.hideAsync();
    }
  }

  useEffect(() => {
    const authSubscriber = FirebaseService.onAuthStateChanged(
      onAuthStateChanged
    );

    // unsubscribe on unmount
    return authSubscriber;
  }, []);

  if (initializing || loading) {
    return <LoadingOnAuth />;
  }
  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        summoner,
        setSummoner,
        setIsSettedSummer,
        isSettedSummoner,
      }}
    >
      <NavigationContainer
        ref={navigationRef}
        onStateChange={async state => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = getActiveRouteName(state);
          if (previousRouteName !== currentRouteName) {
            try {
              await Analytics.setCurrentScreen(currentRouteName);
            } catch (error) {
              Sentry.captureException(error);
            }
          }
          // Save the current route name for later comparision
          routeNameRef.current = currentRouteName;
        }}
      >
        <AuthStackScreens />
      </NavigationContainer>
    </AuthContext.Provider>
  );
};

export default Root;
