import { useState, useEffect } from 'react';
import FirebaseService from '../services/firebase.ts';

const useGetChallenge = (id) => {
  const [challenge, setChallenge] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getChallenge = async () => {
      try {
        setLoading(true);
        const { data } = await FirebaseService.getChallenge(id);
        setChallenge(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getChallenge();
  }, [id]);

  return { ...challenge, loading, setChallenge };
};

export default useGetChallenge;
