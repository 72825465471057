import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { Spinner } from '@ui-kitten/components';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { WrapperPageContent, SectionName, WrapperAds } from '../styles/Shared';
import HistoryCard from '../screens/challenge/extra/HistoryCard.tsx';
import useGetPastChallenges from '../hooks/useGetPastChallenge';
import Layout from '../components/Layout/Layout';
import AdsBanner from '../components/AdsBanner.web.tsx';
import { generateChallengeSlug } from '../utils/index';

const HistoryCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 10px;
`;

const PastChallenge = () => {
  const { pastChallenges, loading } = useGetPastChallenges();
  const onClickChallenge = challenge => navigate(`/challenge/${generateChallengeSlug(challenge)}`);
  const { t } = useTranslation(['history']);

  return (
    <Layout>
      <WrapperPageContent>
        <SectionName style={{ gridColumn: 'span 2' }}>
          {t('pastChallenge')}
        </SectionName>

        <HistoryCardContainer>
          {loading ? (
            <Spinner status="basic" />
          ) : (
            pastChallenges.map((pastChallenge, i) => (
              <HistoryCard
                {...pastChallenge}
                key={i}
                onPress={() => onClickChallenge(pastChallenge)}
              />
            ))
          )}
        </HistoryCardContainer>
        <WrapperAds>
          <AdsBanner inArticle marginBottom={20} />
        </WrapperAds>
      </WrapperPageContent>
    </Layout>
  );
};

export default PastChallenge;
